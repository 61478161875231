<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const props = defineProps<{
  link: {
    name: string
    slug: string | undefined
    labelPath: string
  }
}>()
</script>

<template>
  <NuxtLink
    :to="localePath({ name: link.name, params: { slug: link.slug } })"
    class="group my-auto w-max whitespace-nowrap font-bold no-underline transition duration-300"
  >
    <div>
      {{ t(link.labelPath) }}
      <span
        class="-mt-1 block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover:max-w-full"
        :class="{
          '!max-w-full':
            $route.path ===
            localePath({
              name: link.name,
              params: { slug: link.slug },
            }),
        }"
      />
    </div>
  </NuxtLink>
</template>
